<template>
  <div class="flex justify-end justify-items-center">
    <div
      class="flex language--inner bg-white items-center relative mt-6 mr-6 mb-3 px-2 py-2 rounded-3xl justify-between cursor-pointer w-40 border"
      @mouseleave="languageOpener(false)"
    >
      <ClearLink
        :element-id="`language-selector`"
        :to-link="`javascript:;`"
        class="w-full flex items-center justify-between cursor-pointer gap-2 text-xs relative"
        @mouseenter="languageOpener(true)"
      >
        <div class="rounded-full truncate w-6">
          <ElementsCxlImg :alt="locale" :src="locale" />
        </div>
        <span class="mr-auto text-sm 2xl:text-base">
          {{ locale.toUpperCase() }}
        </span>

        <span class="ml-auto flex items-center top-2 z-40 cursor-pointer w-5 h-5">
          <SvgSprite class="icon w-4 h-4 2xl:w-5 2xl:h-5 fill-transparent stroke-gray-400" symbol="dropdown" />
        </span>
      </ClearLink>
      <div
        v-show="isLanguageOpen"
        class="absolute rounded bg-white border px-2.5 w-36 pb-2.5 pt-1 top-10 z-[1] shadow-lg shadow-gray-300/50"
      >
        <ul class="flex flex-col p-0 m-0 z-40 gap-1">
          <li
            v-for="avlLocale in availableLocales"
            :key="avlLocale.code"
            class="flex items-center gap-1.5 py-1.5"
            @click.prevent.stop="$i18n.setLocale(avlLocale.code)"
          >
            <NuxtLink class="flex items-center justify-end gap-1.5 text-xs relative" to="#">
              <ElementsCxlImg :alt="avlLocale?.code" :src="avlLocale?.code" class="h-3.5" />
              <span class="text-sm 2xl:text-base">{{ avlLocale?.code?.toUpperCase() }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { useI18n } from '#imports';
import { computed, ref } from 'vue';

const { locale, locales } = useI18n();
const availableLocales = computed(() => {
  return locales.value.filter((i) => i !== locale.value);
});
const isLanguageOpen = ref(false);
const languageOpener = (flag) => {
  isLanguageOpen.value = flag;
};
</script>
